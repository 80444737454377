body {
  margin: 0;
  font-family: "Inter", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  justify-content: center;
}

@font-face {
  font-family: "Labil";
  src: local("LabilGrotesk-Bold"),
    url(./assets/LabilGrotesk-Bold.woff) format("woff");
}

@font-face {
  font-family: "LabilR";
  src: local("LabilR"), url(./assets/LabilR.woff) format("woff");
}

@font-face {
  font-family: "Reenie Beanie";
  src: local("Reenie-Beanie"), url(./assets/Reenie-Beanie.woff) format("woff");
}

.active-mixer-exit-active {
  transform: translateX(300px);
  transition: transform 300ms ease-out;
}
.active-mixer-enter {
  transform: translateX(300px);
}
.active-mixer-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in;
}
.icebreaker-container {
  animation-duration: 300ms;
}

button:disabled,
button.disabled {
  cursor: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333Z' stroke='%23B2BCC6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.1084 4.10828L15.8917 15.8916' stroke='%23B2BCC6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      15 15,
    not-allowed;
}

.Toastify__toast-container {
  width: auto !important;
  min-width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#tl,
button[role="menuitem"] {
  padding: 0px 15px 0px 0px;
}

.lounge-tour {
  padding: 8px !important;
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.08) !important;
}

.lounge-tour-mask {
  color: transparent;
}

.lounge-tour-highlighted-mask {
  box-shadow: 0px 0px 0px 2px #a385ff, 0px 0px 0px 4px #e0d6ff;
  border-radius: 20px;
}

.zIndex-1 {
  z-index: 1 !important;
}
